<template>
  <v-dialog v-model="dialogModel" :retain-focus="false" :fullscreen="!smAndUp"
    content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height">
    <v-card :max-height="smAndUp ? '75vh': '100vh'" max-width="100vw" :width="smAndUp ? '500px' : undefined">
      <v-card-title>
        <template v-if="clipboardCopyAlertVisible">
          <div
            class="d-flex justify-center align-center fill-height"
          >
            <v-alert text="Tracker Copied to Clipboard!" type="success" variant="tonal"></v-alert>
          </div>
        </template>
        <template v-if="!clipboardCopyAlertVisible">
          <v-container>
            <v-row justify="start" align="center" no-gutters>
              <v-col>
                <v-img v-if="user.picture" contain max-height="30" max-width="30" :src="user.picture" />
                <v-icon v-else size="small">mdi-account-outline</v-icon>
              </v-col>
              <v-col>
                <span class="text-h7 font-weight-light">{{ user.name }}</span>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="padding-top: 16px; padding-right: 0px; padding-left: 0px; padding-bottom: 10px;">
        <template v-if="authUserContent.trackers.length">
            <div class="ml-2 mb-2">Saved Tracking Numbers</div>
            <v-virtual-scroll :items="authUserContent.trackers" :height="smAndUp ? '50vh' : `${windowHeight - 195}px`">
              <template v-slot:default="{ item }">
                <AuthUserSettingsSavedTrackerListItem
                :isDarkTheme="isDarkTheme"
                :item="item"
                :selectSavedTracker="selectSavedTracker"
                :copyTrackerToClipboard="copyTrackerToClipboard"
                :editSavedTracker="editSavedTracker"
                :reloadTrackerData="reloadTrackerData" />
              </template>
            </v-virtual-scroll>
          </template>
          <template v-if="!authUserContent.trackers.length">
            <div class="d-flex justify-center align-center" style="width: 100%;">
              <v-btn variant="plain" disabled>No Saved Tracking Numbers</v-btn>
            </div>
          </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="dialogModel = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn text color="error" prepend-icon="mdi-account-outline" @click="dialogModel = false; logInOrOut()">
          Log Out
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <TrackerSaveDialog v-model="showEditDialog" :isUpdate="true" :tracker="trackerToEdit" :carrier="carrierToEdit"
    :description="descriptionToEdit" :deleteTrackerFunction="deleteSavedTracker"></TrackerSaveDialog>
</template>

<script>
import { inject, onMounted, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import {
  prettyStringForEnum,
  prettyDateWithFormat,
  prettyETAStringFromStats,
  prettyLocationStringFromLocation
} from '../utils/trackingUtils.js'
import { getUserTrackers, deleteUserTracker } from '../utils/authUtils.js'
import { fetchTrackingInfo } from '../utils/trackingUtils.js'
import TrackerSaveDialog from './TrackerSaveDialog.vue'
import AuthUserSettingsSavedTrackerListItem from './AuthUserSettingsSavedTrackerListItem.vue'
import { copyText } from 'vue3-clipboard'
import pLimit from 'p-limit'

export default {
    name: 'AuthUserSettingsDialog',
    components: { TrackerSaveDialog, AuthUserSettingsSavedTrackerListItem },
    emits: ['update:modelValue', 'tracker-selected', 'saved-trackers-updated'],
    props: {
      modelValue: {
          type: Boolean,
          required: true
      }
    },
    setup(props, context) {
      // Logger
      const logger = inject('vuejs3-logger')

      const dialogModel = ref(false)

      //Theme Handling
      const theme = useTheme()
      const isDarkTheme = ref(theme.current.value === 'dark')
      watch(theme.current, () => {
        if (theme.current.value === 'dark') {
          isDarkTheme.value = true
        } else {
          isDarkTheme.value = false
        }
      })

      // Reactivity Logic
      const { smAndUp } = useDisplay()

      watch(dialogModel, (val) => {
        context.emit('update:modelValue', val) // Pass change up to parent component
      })

      // User Authentication
      const { getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
      const logInOrOut = () => {
        if (isAuthenticated.value === true) {
          // Log out
          logout({ returnTo: window.location.origin })
        } else {
          loginWithRedirect()
        }
      }

      // Authenticated User Content
      const isLoading = ref(false)
      const authUserContent = reactive({
        trackers: [],
        selectedTracker: null
      })

      // Fetch full list w/ status (limited concurrency)
      const fetchLimit = pLimit(20)
      const updateLimit = pLimit(1) // limit updates to the saved trackers

      const reloadTrackerData = async (tracker, carrier) => {
        logger.debug(`Requesting tracker info for tracker ${tracker} (${carrier})... `)
        fetchLimit(async () => {
          // Only enrich if dialog is visible
          if (dialogModel.value) {
            const trackingData = await fetchTrackingInfo(tracker, carrier, user.value?.sub)
            const lastHop = trackingData?.hops?.slice(-1)?.[0]
            const location = {
                city: lastHop?.city ?? '',
                state: lastHop?.state ?? '',
                zip: lastHop?.zip ?? '',
                country: lastHop?.country ?? '',
            }
            
            updateLimit(async () => {
              if (authUserContent.trackers.length) {
                let trackerIdx = 0
                for (const aTracker of authUserContent.trackers) {
                  if (aTracker.tracker === tracker && aTracker.carrier === carrier) {
                    authUserContent.trackers.splice(trackerIdx, 1, {
                      ...aTracker,
                      status: trackingData?.stats ?? {},
                      location
                    })
                    break // for
                  }
                  trackerIdx += 1
                }
              }
            })
          }
        })
      }

      const updateUserContent = async (isAuthd) => {
        isLoading.value = true
        let savedTrackers = []
        if (isAuthd) {
          const token = await getAccessTokenSilently()
          // Fetch bare list content
          savedTrackers = await getUserTrackers(token, user.value?.sub)
          authUserContent.trackers = savedTrackers
        }
        isLoading.value = false
        // emit saved-trackers-updated
        context.emit('saved-trackers-updated', savedTrackers) // Pass latest trackers to parent
      }

      // Delete Saved Tracker Logic
      const deleteSavedTracker = async (tracker) => {
        if (tracker?.tracker) {
          // Clear selection (if necessary)
          if (authUserContent.selectedTracker === tracker.tracker) {
            authUserContent.selectedTracker = null
          }
          const deletedTracker = await deleteUserTracker(await getAccessTokenSilently(), user.value?.sub, tracker.tracker)
          logger.debug(`Deleted saved user tracker: ${JSON.stringify(deletedTracker)}`)
          await updateUserContent(isAuthenticated.value) // Refresh list of saved trackers
        }
      }

      // Update Saved Tracker Logic
      const showEditDialog = ref(false)
      const trackerToEdit = ref('')
      const carrierToEdit = ref('')
      const descriptionToEdit = ref('')

      const editSavedTracker = (tracker) => {
        if (tracker?.tracker) {
          // Set the props for the edit component
          trackerToEdit.value = tracker.tracker
          carrierToEdit.value = tracker.carrier
          descriptionToEdit.value = tracker.description
          // Show the Edit dialiog
          showEditDialog.value = true
        }
      }

      const selectSavedTracker = (tracker) => {
        dialogModel.value = false // close the dialog
        context.emit('tracker-selected', tracker) // Pass selected tracker to parent
      }

      // Watch for edit dialog to dismiss
      watch(() => showEditDialog.value, async (val) => {
        if (val === false) {
          await updateUserContent(isAuthenticated.value) // Refresh list of saved trackers
        }
      })

      // V-Model 2-Way Binding
      watch(() => props.modelValue, async (val) => {
        dialogModel.value = val
        if (val) {
          // Populate user data on dialog open
          await updateUserContent(isAuthenticated.value)
        }
      }, { immediate: true })

      // Dynamic screen size logic (TODO: use vuetify logic)
      const windowHeight = ref(window.innerHeight)
      const windowWidth = ref(window.innerWidth)

      const windowResizeHandler = () => {
        windowHeight.value = window.innerHeight
        windowWidth.value = window.innerWidth
      }

      // Clipboard (Copy Tracker)
      const clipboardCopyAlertVisible = ref(false)
      const copyTrackerToClipboard = (tracker) => {
        copyText(tracker.tracker, undefined, (error, event) => {
          if (error) {
            logger.error(`Couldn't copy tracker info to clipbard: ${error}`)
          } else {
            logger.debug(`Copied tracker (${tracker.tracker}) to clipboard: ${event}`)
            clipboardCopyAlertVisible.value = true
            setTimeout(() => {
              clipboardCopyAlertVisible.value = false
            }, 3000);
          }
        })
      } 

      onMounted(() => {
        window.addEventListener('resize', windowResizeHandler)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', windowResizeHandler)
      })

      return {
        dialogModel,
        isDarkTheme,
        smAndUp,    // size class bool
        windowHeight,
        windowWidth,
        showEditDialog,
        user,
        isLoading,
        authUserContent,
        deleteSavedTracker,
        editSavedTracker,
        selectSavedTracker,
        reloadTrackerData,
        logInOrOut,
        trackerToEdit,
        carrierToEdit,
        descriptionToEdit,
        prettyStringForEnum,
        prettyDateWithFormat,
        prettyETAStringFromStats,
        prettyLocationStringFromLocation,
        // Clipboard Data
        clipboardCopyAlertVisible,
        // Clipboard Functions
        copyTrackerToClipboard
      }
    }
}
</script>
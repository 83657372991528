<template>
  <v-app :theme="currentTheme">
    <v-main>
      <v-container class="pt-0 pb-0" fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="d-flex justify-start align-center flex-column flex-sm-row fill-height">
            <div :class="mdAndUp && footerEnabled ? ['text-h4'] : ['text-h6']">{{ componentTitle }}</div>
            <v-btn size="x-small" variant="plain" disabled>
              v{{ projectVersion }}
            </v-btn>
            <!-- TODO: There's probably a better way to justify this using a proper grid -->
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <!-- User Logged In -->
            <v-btn class="ml-3 mr-3" :variant="user.picture ? 'plain' : 'outlined'" icon v-if="isAuthenticated" size="x-small"
              color="grey" @click="menuClicked">
              <v-img v-if="user.picture" contain width="30" :src="user.picture" />
              <v-icon v-else>
                mdi-account-outline
              </v-icon>
              <v-tooltip activator="parent" open-delay="2000" location="bottom" origin="end">{{ loggedInUserMessage }}</v-tooltip>
            </v-btn>
            <!-- No User Logged In -->
            <v-btn class="ml-3 mr-3" v-if="!isAuthenticated" variant="outlined" size="x-small" prepend-icon="mdi-account-outline"
              color="grey" @click="logInOrOut">
              Login
              <v-tooltip activator="parent" open-delay="1000" location="bottom" origin="end">{{ loginBenefitMesssage }}</v-tooltip>
            </v-btn>
            <v-switch v-model="darkThemeSwitch" hide-details
              :prepend-icon="darkThemeSwitch ? 'mdi-weather-night' : 'mdi-white-balance-sunny'"></v-switch>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SingleTracker
      :landscapeMode="landscapeEnabled"
      :height="mainContentHeight"
      :tracker="selectedTracker"
      :savedTrackers="savedTrackers"
      :isDarkTheme="darkThemeSwitch"
      @saved-tracker-updated="savedTrackerUpdated"
      @tracker-input-updated="trackerInputUpdated"
      @valid-tracker-input-updated="validTrackerInputUpdated"
      class="pt-0 pb-3 pl-1 pr-1" />
    <v-footer v-if="footerEnabled" :style="{ padding: 0, 'height': footerContentHeight + 'px' }">
      <v-card elevation="0" rounded="0" width="100%" height="100%" class="text-center">
        <v-card-text>
          <v-container style="padding: 0;">
            <v-row justify="center" align="center" no-gutters>
              <v-col>
                <v-btn variant="plain" disabled style="display: inline;">
                  Powered by
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" no-gutters>
              <v-col lg="2" md="2" sm="3">
                <v-btn flat @click="visitShippo" style="display: inline;">
                  <v-img :width="100" :src="require('@/assets/shippo_logo.svg')"></v-img>
                </v-btn>
              </v-col>
              <v-col lg="2" md="2" sm="3">
                <v-btn flat @click="visitGCP" style="display: inline;">
                  <v-img :width="120" :src="require('@/assets/google_cloud_logo.png')"></v-img>
                </v-btn>
              </v-col>
              <v-col lg="2" md="2" sm="3">
                <!-- Due to a Vue3 CSS/theme? bug, using two Hasura icons here + v-if rather than a computed logo path prop -->
                <v-btn v-if="darkThemeSwitch" flat @click="visitHasura" style="display: inline;">
                  <v-img :width="80" :src="require('@/assets/hasura-logo-primary-light.png')"></v-img>
                </v-btn>
                <v-btn v-else flat @click="visitHasura" style="display: inline;">
                  <v-img :width="80" :src="require('@/assets/hasura-logo-primary-dark.png')"></v-img>
                </v-btn>
              </v-col>
              <v-col lg="2" md="2" sm="3">
                <v-btn v-if="darkThemeSwitch" flat @click="visitAuth0" style="display: inline;">
                  <v-img :width="80" :src="require('@/assets/auth0-logo-primary-light.svg')"></v-img>
                </v-btn>
                <v-btn v-else flat @click="visitAuth0" style="display: inline;">
                  <v-img :width="80" :src="require('@/assets/auth0-logo-primary-dark.svg')"></v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" no-gutters style="height: 40px;">
              <v-col justify-self="end">
                <p>
                  <a href="http://www.mjchp.com/" style="text-decoration:none" target="_blank">
                    <MHPSLogo class="mr-2" :abbreviated="true" :maxFontSize="20"/>
                  </a>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
    <div v-if="!footerEnabled && windowHeight > 450"
      class="d-flex flex-column justify-end align-center"
      :style="{ padding: 0, 'min-height': footerContentHeight + 'px' }"
      >
      <p class="text-center">
        <a href="http://www.mjchp.com/" style="text-decoration:none" target="_blank">
          <MHPSLogo :abbreviated="true" :maxFontSize="20"/>
        </a>
      </p>
    </div>
    <AuthUserSettingsDialog
      v-model="authMenuOpen"
      @tracker-selected="trackerSelected"
      @saved-trackers-updated="savedTrackersUpdated"
    ></AuthUserSettingsDialog>
    </v-main>
  </v-app>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, inject, ref, watch } from 'vue'
import SingleTracker from './components/SingleTracker.vue'
import { useAuth0 } from '@auth0/auth0-vue'
import AuthUserSettingsDialog from './components/AuthUserSettingsDialog.vue'
import { useDisplay } from 'vuetify'
import { prettyStringForEnum } from './utils/trackingUtils'
import { getUserTrackers } from './utils/authUtils.js'
import { useRoute, useRouter } from "vue-router"
import MHPSLogo from './components/logos/MHPSLogo.vue'

export default {
  name: 'App',
  head() {
    return {
    // Children can override the title.
    title: `i•Track•bro v${process.env.PACKAGE_VERSION}`,
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    // titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'A Better Package Tracker.'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'A Better Package Tracker.'},
      {property: 'og:site_name', content: 'A Better Package Tracker.'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.itrackbro.com/'},
      {property: 'og:image', content: 'https://www.itrackbro.com/favicon.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'A Better Package Tracker.'},
      // Google / Schema.org markup:
      {itemprop: 'name', content: `i•Track•bro v${process.env.PACKAGE_VERSION}`},
      {itemprop: 'description', content: 'A Better Package Tracker.'},
      {itemprop: 'image', content: 'https://www.itrackbro.com/favicon.png'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.itrackbro.com/'}
    ]
  }
  },
  components: {
    SingleTracker, AuthUserSettingsDialog, MHPSLogo
  },
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // App Constants
    const componentTitle = ref('i•Track•bro 📦😬✉️')
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

    // Window Size Mgmt

    // Footer enabled for larger screens only
    const minFooterHeight = 850 // pixels
    const minFooterWidth = 500 // pixels

    // Landscape Mode enabled for wider screens
    const minLandsdcapeWidth = 700 // pixels

    const { mdAndUp, smAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)
    const footerEnabled = ref(window.innerHeight >= minFooterHeight && window.innerWidth >= minFooterWidth && mdAndUp.value)
    const landscapeEnabled = ref(window.innerWidth >= minLandsdcapeWidth)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
      footerEnabled.value = window.innerHeight >= minFooterHeight && window.innerWidth >= minFooterWidth && mdAndUp.value
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
      footerEnabled.value = window.innerHeight >= minFooterHeight && window.innerWidth >= minFooterWidth && mdAndUp.value
      landscapeEnabled.value = window.innerWidth >= minLandsdcapeWidth
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    const footerContentHeight = computed(() => {
      return footerEnabled.value ? 130 : 30
    })

    const mainContentHeight = computed(() => {
      const appBarHeight = smAndUp.value ? 45 : 125
      return windowHeight.value - footerContentHeight.value - appBarHeight
    })

    onMounted(() => {
      window.addEventListener('resize', windowResizeHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', windowResizeHandler)
    })
    
    // Theme Mgmt
    const currentTheme = ref('dark')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
      } else {
        currentTheme.value = 'light'
      }
    })

    // External Links
    const visitShippo = () => {
      window.open('https://goshippo.com/');
    }

    const visitGCP = () => {
      window.open('https://cloud.google.com')
    }

    const visitHasura = () => {
      window.open('https://hasura.io')
    }

    const visitAuth0 = () => {
      window.open('https://auth0.com')
    }

    // Router
    const router = useRouter()

    // User Authentication
    const loginBenefitMesssage = ref('Sign up for free to save your tracking numbers!')
    const { getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
    const logInOrOut = () => {
      if (isAuthenticated.value === true) {
        // Log out
        logout({ returnTo: window.location.origin })
      } else {
        loginWithRedirect({ appState: { target: (trackerInput.value ? `/tracker/${trackerInput.value}` : undefined ) } })
      }
    }

    onMounted(() => {
      setTimeout(async () => {
        // Try resuming existing session via a all to getAccessTokenSilently()
        if (!isAuthenticated.value) {
          try {
            await getAccessTokenSilently()
            logger.info(`CheckSession success.`)
          } catch (error) {
            logger.info(`CheckSession returned: ${error}`)
          }
        }
      }, 2000)
    })

    // Maintain Logged In User Message
    const loggedInUserMessage = ref('')
    watch(user, (thisUser) => {
      if (thisUser?.name) {
        loggedInUserMessage.value = `Logged In As: ${thisUser.name}`
      } else {
        loggedInUserMessage.value = ''
      }
    }, { immediate: true, deep: true })

    // Authenticated User Content
    const authMenuOpen = ref(false)
    const selectedTracker = ref(null) // tracker value picked from saved items
    const trackerInput = ref(null)  // any (partial) input by user
    const savedTrackers = ref(null)

    const refetchTrackers = async () => {
      // Fetch content
      savedTrackers.value = (await getUserTrackers(await getAccessTokenSilently(), user.value?.sub)) ?? null
    }

    // Load saved trackers when auth status changes to true
    // Close user menu if auth status changes to false
    watch(isAuthenticated, async (isAuthd) => {
      if (isAuthd) {
        await refetchTrackers()
      } else {
        authMenuOpen.value = false
      }
    })
    // Open auth user settings dialog when menu clicked
    const menuClicked = async () => {
      if (authMenuOpen.value) {
        authMenuOpen.value = false
      } else if (isAuthenticated.value && user.value) {
        authMenuOpen.value = true
      }
    }

    // Update the tracker view if user selects a saved tracker
    const trackerSelected = (tracker) => {
      selectedTracker.value = tracker
      router.push(`/tracker/${tracker}`)
    }

    // Keep (raw) user input for redirects
    const trackerInputUpdated = (newInput) => {
      trackerInput.value = newInput
    }

    const validTrackerInputUpdated = (newValidTracker) => {
      router.push(`/tracker/${newValidTracker}`)
    }

    // Update the saved trackers
    const savedTrackersUpdated = (newTrackers) => {
      savedTrackers.value = newTrackers
    }

    // Single tracker was updated
    const savedTrackerUpdated = async () => {
      await refetchTrackers()
    }

    // Tracker from URL
    const route = useRoute()
    watch(
      () => route.params.tracker,
      newTracker => {
        logger.debug(`Tracker from URL is: ${newTracker}`)
        if (newTracker) {
          trackerSelected(newTracker)
        }
      }
    )

    return {
      componentTitle,
      projectVersion,
      visitShippo,
      visitGCP,
      visitHasura,
      visitAuth0,
      currentTheme,
      darkThemeSwitch,
      mdAndUp, // size class bool
      windowHeight,
      loginBenefitMesssage,
      logInOrOut,
      isAuthenticated,
      user,
      menuClicked,
      authMenuOpen,
      selectedTracker,
      savedTrackers,
      loggedInUserMessage,
      // Responsive Sizing
      footerContentHeight,
      mainContentHeight,
      footerEnabled,
      landscapeEnabled,
      // Tracking Utils
      prettyStringForEnum,
      trackerSelected,
      savedTrackersUpdated,
      savedTrackerUpdated,
      trackerInputUpdated,
      validTrackerInputUpdated
    }
  }
}
</script>
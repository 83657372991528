import tnv from 'tracking-number-validation'
import { userIdHeaderName } from './authUtils.js'
import moment from 'moment'

import fetchRetryWrapper from 'fetch-retry'

const fetchRetry = fetchRetryWrapper(fetch, {
    retries: 5,
    retryOn: [429, 500, 501, 502, 503, 504, 520, 521, 522, 523, 524]
  })

export const fetchTrackingInfo = async (trackingNumber, carrier, userId) => {
    const headers = {}

    if (userId) {
        // Add header for relaxed rate limiting
        headers[userIdHeaderName] = userId
    }

    // Use the backend proxy service
    const result = await fetchRetry(`${process.env.VUE_APP_API}/status/${carrier}/${trackingNumber}`, {
        method: 'GET',
        headers,
        retryDelay: function(attempt) {
            return Math.pow(2, attempt) * 500; // 500, 1000, 2000, 4000
          },
    })

    if (result?.status === 200) {
        const raw = await result.json()
        return raw.status
    }

    return { status: result?.status ?? 0 }
}

export const prettyStringForEnum = (rawString) => {
    let pretty = rawString
    switch (rawString) {
        // Tracking Status Enums
        case 'pickup_available':
            pretty = 'Pickup Available'
            break
        case 'information_received':
            pretty = 'Shipment Information Received'
            break
        case 'package_accepted':
            pretty = 'Package Accepted'
            break
        case 'package_departed':
            pretty = 'Package Departed'
            break
        case 'package_arrived':
            pretty = 'Package Arrived'
            break
        case 'delivery_scheduled':
            pretty = 'Delivery Scheduled'
            break
        case 'delivery_rescheduled':
            pretty = 'Delivery Rescheduled'
            break
        case 'package_processing':
            pretty = 'Package Processing'
            break
        case 'package_processed':
            pretty = 'Package Processed'
            break
        case 'other':
            pretty = 'Other'
            break
        case 'delayed':
            pretty = 'Delayed'
            break
        case 'address_issue':
            pretty = 'Address Issue'
            break
        case 'out_for_delivery':
            pretty = 'Out For Delivery'
            break
        case 'return_to_sender':
            pretty = 'Return To Sender'
            break
        case 'package_held':
            pretty = 'Package On Hold'
            break
        case 'package_forwarded':
            pretty = 'Package Forwarded'
            break
        case 'delivered':
        case 'DELIVERED':
            pretty = 'Delivered'
            break
        case 'notice_left':
            pretty = 'Notice Left'
            break
        case 'TRANSIT':
            pretty = 'In Transit'
            break
        case 'PRE_TRANSIT':
            pretty = 'Pre-Shipment'
            break
        case 'RETURNED':
            pretty = 'Returned'
            break
        // Couriers
        case 'dhl_express':
            pretty = 'DHL EXPRESS'
            break
        case 'usps':
            pretty = 'USPS'
            break
        case 'fedex':
            pretty = 'FEDEX'
            break
        case 'ups':
            pretty = 'UPS'
            break
        case 'ontrac':
            pretty = 'ONTRAC'
            break
        default:
            break
    }
    return pretty
}

const isRoyalMailInternationExportTracker = (tracker) => {
    return tracker?.length === 13 && tracker?.toLowerCase().slice(-2) === 'gb'
}

const isLaPosteInternationExportTracker = (tracker) => {
    return tracker?.length === 13 && tracker?.toLowerCase().slice(-2) === 'fr'
}

const isCanadaPostInternationalExportTracker = (tracker) => {
    return tracker?.length === 13 && tracker?.toLowerCase().slice(-2) == 'ca'
}

const isUPSMailInnovationsExpeditedTracker = (tracker) => {
    return tracker?.length === 18 && /^\d+$/.test(tracker)
}

export const trackerIsValid = (tracker) => {
    return tnv.isValid(tracker)
        || isRoyalMailInternationExportTracker(tracker)
        || isLaPosteInternationExportTracker(tracker)
        || isUPSMailInnovationsExpeditedTracker(tracker)
        || isCanadaPostInternationalExportTracker(tracker)
}

const courierEnumMap = {
    dhl: 'dhl_express'
}

export const courierForTracker = (tracker) => {
    let courier
    
    // Try library first
    const libParsedCourier = tnv.getCourierOne(tracker)
    if (libParsedCourier) {
        courier = libParsedCourier
    } else if (isRoyalMailInternationExportTracker(tracker) || isLaPosteInternationExportTracker(tracker) || isCanadaPostInternationalExportTracker(tracker)) {
        // HACK: some international postal services are assumed 'USPS'
        courier = 'usps'
    } else if (isUPSMailInnovationsExpeditedTracker(tracker)) {
        courier = 'ups'
    }
    return courierEnumMap[courier] ?? courier
}

export const isDatePast = (rawDate) => {
    return moment(rawDate).isBefore() // before now by default
  }

export const prettyDate = (rawDate) => {
    return moment(rawDate).format('MMMM Do YYYY')
}

export const prettyDateWithFormat = (rawDate, dateFormat = null) => {
    return moment(rawDate).format(dateFormat ? dateFormat: 'MMMM Do YYYY')
}

export const prettyETAStringFromStats = (stats, dateFormat) => {
    return stats?.eta && !isDatePast(stats.eta) ? `ETA: ${prettyDateWithFormat(stats.eta, dateFormat)}` : ''
}

export const prettyLocationStringFromLocation = (location) => {
    const locDesc = `${location?.city ? location.city : ''}`
        + `${location?.state ? ', ' + location.state : ''}`
        + `${location?.zip ? ', ' + location.zip : ''}`
        + `${location?.country ? ', ' + location.country : ''}`
    return locDesc ? '📍 ' + locDesc : locDesc
}

export const statusDetailFromLeg = (leg) => {
    let details = ''
    if (leg) {
       if (leg.substatus === 'other') {
        details = leg.carrierStatusDetail ?? leg.statusDetail
       } else {
        details = leg.statusDetail
       }
    }
    return details
}